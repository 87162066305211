<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000" persistent>
      <v-card>
        <v-card-title>
          <back-button color="grey" @click="close" />
          Tipo do oligoelemento: {{ selectedType.name }}
        </v-card-title>
        <v-card-text>
          <span class="subtitle-1">Selecione o oligoelementos</span>
          <v-list three-line>
            <template v-for="element in selectedType.oligoelements">
              <step-item
                :key="element.id"
                :title="element.name"
                class="elevation-2 mb-2 item-par"
                @click="selectOligoelement(element)"
              />
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <oligoelement />
  </div>
</template>

<script>
export default {
  name: "IndexDialog",
  components: {
    StepItem: () =>
      import("@/domains/therapy/shared/presentation/components/StepItem"),
    Oligoelement: () => import("./OligoelementDialog"),
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.oligoelements.types;
      },
      set(value) {
        this.$store.commit("dialogs/setOligoelementsTypes", value);
      },
    },
    selectedType() {
      return this.$store.state.oligoelements.selectedType;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    selectOligoelement(oligoelement) {
      this.$store.commit("oligoelements/selectOligoelement", oligoelement);
      this.$store.commit("dialogs/setOligoelementsSpecific", true);
    },
  },
};
</script>

<style scoped></style>
